import React from 'react';

import Aboutslider from '../components/About/Aboutslider';
import Aboutcontent from '../components/About/Aboutcontent';
import { Row} from 'reactstrap';
import SysFooter from '../components/Footer';

function About() {
  
  return (
    
    <div className="about-page page-top-header">

   
      <Aboutslider/>
      
       
         <Row>
        <Aboutcontent/>
       </Row>
    
       
      <SysFooter/>
     
    </div>
    
    
  );
}

export default About;
