import React from 'react';
import Testimonialslider from '../components/Testimonial/Testimonialslider';
import Testimonialcontent from '../components/Testimonial/Testimonicalcontent';

import SysFooter from '../components/Footer';
function Testimonial() {
  return (
    <div className="testimonial-page page-top-header">
     
   
      
      <Testimonialslider/>
      

        <Testimonialcontent/>
     
      
       
      <SysFooter/>
      
    
    </div>
    
    
  );
}

export default Testimonial;
