import React, { useState } from 'react';
import {
  Container, Row, Col, CardBody,
  CardTitle, CardSubtitle, Modal, ModalHeader, ModalBody
} from 'reactstrap';
import aboutus from '../../images/aboutus_illustaration.png';
import metric from '../../images/aboutus-2.png';
import closearrow from '../../images/closeicon.svg';
import Getstarted from '../getstarted.js';
import leftArrow from '../../images/Teams-arrow-left.svg';
import rightArrow from '../../images/Teams-arrow-right.svg';
import Carousel, { consts } from 'react-elastic-carousel';
import Item from '../Usecases/SimpleItem';
import Teams from '../../data/TeamsData.json';

function Aboutcontent() {
  const [modal, setModal] = useState(false);
  const [person, setPerson] = useState(Teams.balaji);

  const getPersonData = (name) => {
    setModal(!modal);
    setPerson(Teams[name])
  }
  const closeBtn = <button className="close" onClick={() => setModal(!modal)}><img src={closearrow} alt="close"/></button>;
  var breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ]

  const customArrows = ({type, onClick, isEdge}) => {
    const pointer = type === consts.PREV ? leftArrow : rightArrow;
    const arrowStyle = type === consts.PREV ? 'sc-AxjAm gEIJqc rec-arrow left-arrow' : 'sc-AxjAm gEIJqc rec-arrow right-arrow';
    return (
      // <Button onClick={onClick} disabled={isEdge}>
      <div className="rect-arrows">
        <img src={pointer} className={arrowStyle} onClick={onClick} disabled={isEdge} alt="arrow"></img>
        </div>

      // </Button>
    )
  }
  return (
    <div className="career-content-section">
      <Container fluid>
        <Row>
          <Col className="mt-60">
            <h2 className="text-center head"> About Us</h2>
            <div className="bg-light">
              <p className="head-black m-20">
                Systematrix is a boutique company based out of Atlanta. We bring a unique blend of domain
                experience and an advanced algorithmic tool kit. We have helped large financial institutions
                 with consent orders and delivered path breaking high value products.</p>
              <Row >
                <Col md="7" >
                  <p className="abt-para">Our principles have over 50 years of combined experience in Banking including
                  setting up AML transaction monitoring for one of
                  the largest banks in the country. This enables us to implement our AI suite in a
                          manner that it fits the domain perfectly</p>
                </Col>
                <Col md="5">
                  <div className="abt-iluus">
                    <img src={aboutus} className="about" alt="about" />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="mt-20">
          <Col>
            <div className="rectangle-box mp-30">
              <h2 className="rectangle-text">Systematrix is on the continuous journey of cutting edge innovations
and creating bespoke products across industries.</h2>
            </div>
          </Col>
        </Row>
        <Row className="mt-20">
          <Col>
            <ul className="abt-list">
              <li>A Data Science and Software Development company based out of Atlanta  </li>
              <li>Multiple products live in marquee financial institutions </li>
              <li>Over 30 AI/ML deployed in production</li>
              <li>Best in class AI/ML models for AML-TM, EDD, FRAUD, UNDERWRITING </li>
              <li>Boost your Criminal activity detection by 40% augmented with powerful features
                extracted from Open source intelligence</li>
              <li>One stop place for boosting your RPA investments by making your bots self aware and autonomous using
home grown OCR, Computer vision and NLP</li>
            </ul>
          </Col>
        </Row>
        <Row className="mt-20">
          <Col>
            <h2 class="text-center head"> Our Mission</h2>
            <div className="rectangle-box mp-30">
              <h2 className="rectangle-text text-center">
                Our Mission is to predict for toxicity or opportunity by eking out patterns
                from the customers interactions with any corporation, keeping in mind such
                interactions form just a small part of the customers overall ecosystem.</h2>
            </div>
          </Col>
        </Row>
        <Row className="mt-20">
          <Col>
            <h2 class="text-center head"> We are a Metric Based Organization</h2>
            <div className="m-20 fit-img">
              <img src={metric} className="text-center" alt="logo" />
            </div>
          </Col>
        </Row>
        <Container fluid className="teams">
          <h2 class="text-center head"> Meet Our Team</h2>
          <Row>
            <Carousel className="systemarix-textcarsolue" breakPoints={breakPoints} renderArrow={customArrows}>
              {
                Object.keys(Teams).map((key) => {
                  var member = Teams[key];
                  return <Item className="card">
                          <CardBody onClick={() => getPersonData(key)}>
                            <div className="text-center">
                              <img  src={require("../../images/Teams/"+member.image)} alt={member.name} className="team-image rounded-circle" />
                            </div>
                            <CardTitle className="text-center mem-name">
                              {member.name}
                            </CardTitle>
                            <CardSubtitle> {member.designation}</CardSubtitle>
                           
                          </CardBody>
                        </Item>
                })
              }
            </Carousel>
          </Row>
          <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg" className="teams-modal">
            <ModalHeader toggle={() => setModal(!modal)} close={closeBtn}>
              <Row>
                <Col md="3" className="circle mn-padding">
                  <img  src={require("../../images/Teams/"+person.image)} alt={person.name} className="modal-image" />
                </Col>
                <Col md="9" className="team-member mn-padding">
                  <div className="team-m">
                  <h2>{person.name}</h2>
                  <h4>{person.designation}</h4>
              
                  </div>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody>
              
              {person.description.map(desc => {
                return <p className="teams-desc">{desc}</p>
              })}
              
            </ModalBody>
          </Modal>      
        </Container>
      </Container>
      <Container fluid>
        <Getstarted />
      </Container>
    </div>
  );
}
export default Aboutcontent;