import React from 'react';
import Mainmenu from '../Mainmenu';
import { Container} from 'reactstrap';
import arrow1 from '../../images/arrow-down-alt2.svg';
function Careerslider() {
  return (

      
       <div className="page-header-img">
   
   <Container fluid>
         <div>
          <Mainmenu tabName="careers" color="#584e3c"/>
         <div className="page-header-content">
            <div className="content-headtext">
             <h2 className="text-center">Join our team </h2>
             <h3 className="text-center mt-15">Your voice counts. Your insights are welcome. Help us build great AI</h3>
             <h3 className="text-center mt-5"><img src={arrow1}  alt="logo" className="arrow-slider"/> </h3>
             </div>
             </div>
         </div>
      
    </Container>
       </div>

     
    
    
  );
}

export default Careerslider;
