import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText, Row, Col, Container } from 'reactstrap';
import classnames from 'classnames';
import arrowright from '../../images/arrow-right.svg';
import backarrow from '../../images/back-arrow.svg';
import JobsData from '../../data/JobsData.json';

var cities = JobsData.cities;
var jobsList = JobsData.jobsList;

const Careercontent = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [showDesc, setShowDesc] = useState(false);
  const [jobId, setJobId] = useState(2);
  const [cityId, setCityId] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
    setShowDesc(false);
  }

  const showJobs = () => {
    setShowDesc(false);
  }

  const showDescription = (jobId, cityId) => {
    var jobs = jobsList[cityId];
    jobs.forEach(job => {
      if(job.id === jobId){
        // console.log(job);
        if(job.description.length > 0) {
          setCityId(cityId);
          setJobId(jobId);
          setShowDesc(true);
        }
      }
    })
    
  }

  var job;
  jobsList[cityId].forEach(jb => {
    let find = true;
    if(jb.id === jobId && find){
      find = false;
      job = jb;
    }
  });

  return (
    <div className="career-content-section">
      <Container fluid>
        <Row>
          <Col className="mt-60">
            <h2 className="text-center head"> Work with us</h2>
            <p className="head-black text-center m-20 pno">
              Systematrix envisions simplifying business processes with advanced Algorithmic models.
                        Our team is motivated to delve deep into Data Science to create cutting edge products.</p>
          </Col>
        </Row>
        <Row>
          <Col> <h3 className="text-center head"> Current Openings on our locations</h3></Col>
        </Row>
        <Row className="career-navtabs">
          <Col md="12">
            <div>
              <Row>
                <Col md="12">
                  <Nav tabs className="ml-mr">
                    {
                      cities.map((city) => {
                        return <NavItem>
                                  <NavLink
                                    className={classnames({ active: activeTab === city.id })}
                                    onClick={() => { toggle(city.id); }}>
                                    Jobs at {city.name}
                                  </NavLink>
                                </NavItem>
                              
                      })
                    }
                  </Nav>
                </Col>
              </Row>
              <TabContent activeTab={activeTab} className={showDesc ? "hide" : ""} >
                {
                  cities.map(city => {
                    return <TabPane tabId={city.id} className="ml-mr pl-pr">
                              <Row>
                                <Col sm="12">
                                  <Row>
                                    {
                                      jobsList[city.id].map(job => {
                                        return <Col sm="6">
                                                <Card body>
                                                  <Row>
                                                    <Col md={{ size: 10, offset: 1 }}>
                                                      <CardTitle>{job.title}</CardTitle>
                                                      <CardText>{job.text}</CardText>
                                                      <span className="readmore-link" onClick={ () => showDescription(job.id,city.id)}>
                                                        Read More <img src={arrowright} alt="arrowright" />
                                                      </span>
                                                    </Col>
                                                  </Row>
                                                </Card>
                                              </Col>
                                      })
                                    }
                                  </Row>
                                </Col>
                              </Row>
                            </TabPane>                                  
                  })
                }
              </TabContent>
              <div id="job_description" className={showDesc ? "" : "hide"}>
                <Row>
                  <Col md={{ size: 10, offset: 1 }}> <span onClick={()=>showJobs()} className="back-btn"><img src={backarrow} alt="backarrow" />  <span className="back-btn-text">Back to see more jobs</span></span></Col>
                </Row>
                <Row className="job-details">
                  <Col md={{ size: 10, offset: 1 }}>
                    <h2 className="job-title-head">
                      {job.title}
                    </h2>
                    <div className="job-des">
                      <h3>  Job description</h3>
                      <h5>Responsibilities and Duties:</h5>
                      <ul className="jobdes-list">
                        { job.description.map(desc => {
                           return <li>{desc}</li>
                          }) 
                        }
                      </ul>
                      <h3>  Job requirements</h3>
                      <ul className="jobdes-list">
                        { job.requirements.map(req => {
                           return <li>{req}</li>
                          }) 
                        }
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 8, offset: 2 }}>
                    <h2 className="text-center btm-text info"> If you think you fit in with the above requirements we would love to talk to you
                              about working in our organization.</h2>
                  </Col>
                </Row>
                <Row className="getstarted-section">
                  <Col>
                    <h2 className="text-center p20 btm-text"> To apply for the job please mention the job title &</h2>
                    <h2 className="text-center btm-text">send us your resume -&nbsp;
                      <a href="mailto:careers@systematrix.ai" className="no-border"><span className="mail-info">careers@systematrix.ai</span></a>
                    </h2>
                    <div className="m-20 text-center">
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        
     </Container>
    </div>
  );
}

export default Careercontent;

