import React from 'react';
import Contactlider from '../components/Contact/Contactslider';
import Contactcontent from '../components/Contact/Contactcontent';
import {  Row } from 'reactstrap';
import SysFooter from '../components/Footer';
function Contact() {
  return (
    <div className="contact-page page-top-header">
     
     
      
      <Contactlider/>
      
      <Row className="no-margin">
        <Contactcontent/>
      </Row>
     
       
      <SysFooter/>
      
  
    </div>
    
    
  );
}

export default Contact;
