import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import addressgold from '../../images/giffy/address-gold.gif'
import customermdm from '../../images/giffy/customermdm.gif'
import gngf from '../../images/giffy/gngf.gif'
import mlacces from '../../images/giffy/ml.gif'
import sarparser from '../../images/giffy/sar.gif'
import cognitive  from '../../images/giffy/cognitive.gif'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
           <Tab label="Address Gold Copy" {...a11yProps(0)} className="tab-1"/>
          <Tab label="Customer MDM" {...a11yProps(1)} className="tab-2"/>
          <Tab label="GNFG " {...a11yProps(2)} className="tab-3"/>
          <Tab label="ML Accelerator API" {...a11yProps(3)} className="tab-4"/>
          <Tab label="SAR Parser" {...a11yProps(4)} className="tab-4"/>
          <Tab label="Cognitive Visualization" {...a11yProps(5)} className="tab-5"/>
          <Tab label="Wireintel" {...a11yProps(6)} className="tab-6"/>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className="product-tab-head">
          <h3> Address Gold Copy</h3>
          <div class="fit-img product-tab-img">
          <img src={addressgold}  alt="addressgoldcopy" />

           </div>
          
        </div>
  
      </TabPanel>
      <TabPanel value={value} index={1}>
      <div className="product-tab-head">
          <h3> Customer MDM</h3>
          <div class="fit-img product-tab-img">
          <img src={customermdm}  alt="customermdm" />

           </div>
          
        </div>
     
      </TabPanel>
      <TabPanel value={value} index={2}>
      <div className="product-tab-head">
          <h3> GNFG</h3>
          <div class="fit-img product-tab-img">
          <img src={ gngf}  alt="gngf" />

           </div>
          
        </div>
     
      </TabPanel>
      <TabPanel value={value} index={3}>
      <div className="product-tab-head">
          <h3> ML Accelerator API</h3>
          <div class="fit-img product-tab-img">
          <img src={mlacces}  alt="ML Accelerator API" />

           </div>
          
        </div>
      
      </TabPanel>
      <TabPanel value={value} index={4}>
      <div className="product-tab-head">
          <h3> SAR Parser</h3>
          <div class="fit-img product-tab-img">
          <img src={sarparser}  alt="sarparser" />

           </div>
          
        </div>
    
      </TabPanel>
      <TabPanel value={value} index={5}>
      <div className="product-tab-head">
          <h3> Cognitive Visualization</h3>
          <div class="fit-img product-tab-img">
          <img src={cognitive} alt="cognitive" />

           </div>
          
        </div>
     
      </TabPanel>
      <TabPanel value={value} index={6}>
      <div className="product-tab-head">
          <h3> Wireintel</h3>
          <div class="fit-img product-tab-img">
          <img alt="wireparser " src={require("../../images/giffy/wireintel.gif")}/>
           </div>
          
        </div>
     
      </TabPanel>
    </div>
  );
}