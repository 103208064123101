import React, { useState } from 'react';
import { Container, Row, Col, Button,Collapse,CardText, CardBody,
    CardTitle } from 'reactstrap';
import leftArrow from '../../images/product-arrow-left.svg';
import rightArrow from '../../images/product-arrow-right.svg';   
import Usecasetabs from './Usecasetabs';
import Carousel, { consts } from 'react-elastic-carousel';
import Item from './SimpleItem';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';



    function Usecasecontent(props) { 
      const [isOpen, setIsOpen] = useState(true);

      const toggle = () => setIsOpen(!isOpen);
 

      
         var breakPoints = [
          { width: 1, itemsToShow: 1 },
          { width: 550, itemsToShow: 2, itemsToScroll: 2 },
          { width: 850, itemsToShow: 3 },
          { width: 1150, itemsToShow: 3, itemsToScroll: 3 },
          { width: 1450, itemsToShow: 5 },
          { width: 1750, itemsToShow: 6 },
        ]
        
        const customArrows = ({type, onClick, isEdge}) => {
          const pointer = type === consts.PREV ? leftArrow : rightArrow;
          const arrowStyle = type === consts.PREV ? 'sc-AxjAm gEIJqc rec-arrow left-arrow' : 'sc-AxjAm gEIJqc rec-arrow right-arrow';
          return (
            // <Button onClick={onClick} disabled={isEdge}>
            <div className="rect-arrows">
              <img src={pointer} className={arrowStyle} onClick={onClick} disabled={isEdge} alt="arrow"></img>
              </div>
      
            // </Button>
          )
        }
    
    return (
      <div className="usecase-content-section">
        <Container fluid>
            <Row>
                <Col className="mt-60">
    
                <h2 className="text-center head"> Our Solutions</h2>
                            <p className="head-black text-center m-20 pno"> 
                            Powerful end to end platform for financial crimes that helps you reduce your
operational burden and increases your precision and recall.</p>
                        
                </Col>
                
                
    
            </Row>
            <Row>

            <Carousel breakPoints={breakPoints} renderArrow={customArrows} className="systemarix-textcarsolue">
        <Item className="card">
        <CardBody>
        <img src={require("../../images/financialcrimes.svg")} alt="Finanical Crimes" className="usecase-image"/>
          <CardTitle className="text-center">
         
            Financial Crimes</CardTitle>
         
       
          <ul class="list-home">
                     <li>AML(Transaction Monitoring)</li>
                     <li>KYC and EDD Augmented List screening </li>
                     
                     <li>Human Trafficking </li>
                     <li>Predatory account take over </li>
                     <li>Medicare fraud</li>
                     </ul>

       
        </CardBody>

        </Item>
        <Item className="card"> 
        <CardBody>
        <img src={require("../../images/creditrisk.svg")} alt="Finanical Crimes" className="usecase-image"/>
          <CardTitle className="text-center">Credit risk</CardTitle>
         
          <CardText>
          <ul class="list-home">

<li> Augmented Underwriting scores </li>
<li> Inputs into Line Increase or decrease strategies</li>

<li> Predicting Delinquencies and charge offs</li>

  </ul>

          </CardText>
       
        </CardBody>
        </Item>
        <Item className="card">
          <CardBody>
          <img src={require("../../images/fraudrisk.svg")} alt="Finanical Crimes" className="usecase-image"/>
          <CardTitle className="text-center">Fraud risk</CardTitle>
         
          <CardText>
          <ul class="list-home">
                    <li>Account Take over </li>
                    <li> Synthetic ID detection </li>
                    <li>Bust Outs </li>
                    <li>Application & Authentication Fraud</li>
                  
                     </ul>

          </CardText>
       
        </CardBody></Item>
        <Item className="card">   
        <CardBody>
        <img src={require("../../images/MDM.svg")} alt="Finanical Crimes" className="usecase-image"/>
          <CardTitle className="text-center">MDM</CardTitle>
         
          <CardText>
          <ul class="list-home">
                    <li>MDM of customers governed by CIP </li>
                    <li> MDM of customers not governed by CIP  </li>
                    
                    <li>MDM of suspects (third parties called out by investigators) </li>
                   
                     </ul>

          </CardText>
       
        </CardBody>
    </Item>
        <Item className="card">
        <CardBody>
        <img src={require("../../images/imageprocessing.svg")} alt="Finanical Crimes" className="usecase-image"/>
          <CardTitle className="text-center">Image processing</CardTitle>
         
          <CardText>
          <ul class="list-home">
                    <li> Converting Photo Ids into searchable database</li>
                   
                    <li>Extracting information from videos </li>
                    <li>Satellite images from IP cameras </li>
                    <li>Multilayer authentication using properitary CNN</li>
                    
                     </ul>

          </CardText>
       
        </CardBody>
    
        </Item>
        <Item className="card">  
        <CardBody>
        <img src={require("../../images/Locationintelligence.svg")} alt="Finanical Crimes" className="usecase-image"/>
          <CardTitle className="text-center">Location intelligence</CardTitle>
         
          <CardText>
          <ul class="list-home">
                    <li>Point of sale data to exact location of merchants </li>
                    
                    <li>Embellish with 100 plus attributes </li>
                    <li>Understand your customer versus general foot traffic at any given location</li>
                    
                   
                     </ul>

          </CardText>
       
        </CardBody></Item>
    </Carousel>
  
            </Row>
            <Row>
                <Col>
               
                


                </Col>
         
            </Row>
            <Row className="usecase-textslider">
            <Col></Col>
          </Row>
            <Row>
                <Col>
                <div className="m-20 fit-img aml">
                <img alt="AMLSolutions" src={require("../../images/AMLsolutions.png")}/>
                </div>
                </Col>
        
            </Row>
            <Row>
           <Col>
           <Usecasetabs/>
           </Col>
            
            </Row>
           
            </Container>
            <div className=" mt-60">
            <Row>
            <Col md={{ size: 8, offset: 2 }}>
                <h2 className="orange text-center mt-60 head-txt">
                Gold copy your location <span> or your </span>counterparty <span>with our advanced algorithms.</span>
                </h2>
                </Col>
            </Row>
           
                
            <div className="rectangle-box">
            <Row>
                
                <Col md="12">
                <Collapse isOpen={isOpen}>

                <Row>
                    <Col md="6">
                    <div className="m-20 fit-img">
                <img alt="swipe machine" src={require("../../images/solutionpage-5@2x.png")}/>
                </div>
                    </Col>
                    <Col md="6">
                    <ul className="list-circle">
                           <li> Increase your customer statement accuracy to 95% with our advanced ER engine  </li>
                          <li> Reduce your disputes and operational burden with incomplete information originating from POS  </li>
                          <li> Over 250 miilion variations of POS data has been trained and growing to international locations</li>
                     </ul>
                    </Col>
                </Row>
                </Collapse>
                </Col>
            </Row>
            
            
            <div className={isOpen? "collapse-arrow collapse-rotate" : "collapse-arrow"}>
          <Button classname="" color="link" onClick={toggle}> <img alt="swipe machine" src={require("../../images/arrow-down-alt2.svg")} /></Button>
        </div>
          
            </div>
           </div>

            <Container fluid>
           
            <Row>
                <Col md="12">
                <div className="m-20 fit-img mt-60">
                <img alt="swipe machine" src={require("../../images/platformpage-5.png")}/>
                </div>
                </Col>
            </Row>
           
            
          
        
            </Container>
           
            </div>
  
    )
 
}
export default Usecasecontent;

