import React from 'react';
import logosys from '../images/SYSTEMATRIXLOGO.png';
import { Container, Row, Col,Nav, NavItem,NavLink } from 'reactstrap';

function SysFooter() {
  return (
      <footer>
<section className="footer-section">
    <Container fluid>
     <Row>
         <Col md="2"><img src={logosys}  alt="footerlogo"  className="footer-logo"/></Col>
         <Col md="3"> <h3 className="footer-heading"> About Us</h3>
       
         <p className="fpara">Systematrix is on the
continuous journey of
cutting edge innovations
and creating bespoke
products across
industries.</p>
         </Col>
         <Col md="3"><h3 className="footer-heading link-head"> Links</h3>
        
         <Row>
             <Col md="6" sm="12" className="full-width">
         <Nav vertical className="fbullet">
        <NavItem>
          <NavLink href="/views/">Home </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/Product">Products</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/Usecase">Use Cases</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/About">About Us</NavLink>
        </NavItem>
        </Nav>
        </Col>
        <Col md="6" sm="12" className="full-width">
        <Nav vertical className="fbullet mbullet">
       
         <NavItem>
          <NavLink href="/Testimonial">Testimonials</NavLink>
        </NavItem> 
        <NavItem>
          <NavLink href="/Career">Careers</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/Contact">Contact Us</NavLink>
        </NavItem>
      </Nav>
      </Col>
      </Row>
         </Col>
         <Col md="4"><h3 className="footer-heading"> Contact Us</h3>
        
         <div class="address-info">
          
            <p>#365 Northridge Road Suite</p>
            <p>200 Sandy Springs GA 30350</p>
             <p> Phone: (+1)  614-678-4343</p>
              <p>Email: contact@systematrix.ai</p>
            </div>
         </Col>
     </Row>
     {/* <Row>
       
       <Col md={{ size: 6, offset: 5 }} >
       <div className="copyright-info">
       <p>
       &copy; Systematrix Solutions 2020 </p>
       <p>Terms & Private Policy
       </p>
       </div>
       
     </Col>22

     </Row> */}
    </Container>
    {/* <img alt="footer " src={require("../images/footer-bg-1.png")} className="bottom-image"/> */}
    </section>

   
      <div className="bottom-image">
  <br/>
  <br/>
      </div>
      
    <Container fluid>
      <Row className="copyringts-info no-margin">
      <Col>
       <div className="copyright-info">
       <p>
       &copy; Systematrix Solutions 2020 </p>
       <p>Terms & Privacy Policy
       </p>
       </div>
       
     </Col>

      </Row>
    </Container>
      </footer>
      
  );
}

export default SysFooter;