import React from 'react';
import OverviewHeader from '../components/Overview/OverviewHeader';
import Overviewcontent from '../components/Overview/Overviewcontent';
import { Container, Row } from 'reactstrap';
import SysFooter from '../components/Footer';
function Overview() {
  return (
  <div className="overview">
    <Container fluid>
    <Row>
      <OverviewHeader/>

      </Row>
     </Container>      
 
        <Overviewcontent/>
     
        <SysFooter/> 
     </div>
    
    
  );
}

export default Overview;
