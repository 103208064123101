import React from 'react';
import { Container, Row, Col} from 'reactstrap';
import Producttabs from '../Products/Producttabs';

function Productcontent() {
    return (
        <div className="product-content-section">
        <Container fluid>
        <Row>
                <Col className="mt-60">
    
                <h2 className="text-center head"> Our Product Suite</h2>
                            
                </Col>
                
    
            </Row>
          
       <div className="clearfix"></div>
          <Row>
                <Col md="11" className="mr-10 mobile-nopadding">
                    <div className="scrol-tabs mt-60">
                <Row>
                   
                    <Producttabs/>
                    
            
                </Row>
                </div>
                </Col>
            </Row> 
           
            </Container>
           
            </div>
    );
}

export default Productcontent;