import React from 'react';
import Productslider from '../components/Products/Productslider';
import Productcontent from '../components/Products/Productcontent';
import SysFooter from '../components/Footer';
import Getstarted from '../components/getstarted';
function Product() {
  return (
    <div className="product-page page-top-header">
     
    
      
      <Productslider/>
      
     
      <Productcontent/>
      <Getstarted/>
     
       
      <SysFooter/>
     
   
    </div>
    
    
  );
}

export default Product;
