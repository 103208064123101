
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/jsdom';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import './index.css';
import './App.css'
import  './responsive.css'
import Overview from './views/Overview.js'
import Jobdetails from './components/Career/Jobdetails'
import Contact from './views/Contact'
import Career from './views/Career'
import Product from './views/Product'
import About from './views/About'
import Testimonial from './views/Testimonial'
import Usecase from './views/Usecase'
import * as serviceWorker from './serviceWorker';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import HttpsRedirect from 'react-https-redirect';
ReactDOM.render(
  <HttpsRedirect>
  <BrowserRouter>
  <Switch>
    <Switch>
      <Route path="/Overview" render={props => <Overview {...props} />} />
      
       <Route
        path="/Career"
        render={props => <Career {...props} />}
      />
        <Route
        path="/jobdetails"
        render={props => <Jobdetails {...props} />}
      />
      <Route
        path="/Contact"
        render={props => <Contact {...props} />}
      />
      <Route
        path="/Product"
        render={props => <Product {...props} />}
      />
      <Route
        path="/About"
        render={props => <About {...props} />}
      />
      <Route
        path="/Testimonial"
        render={props => <Testimonial {...props} />}
      />
       <Route
        path="/Usecase"
        render={props => <Usecase {...props} />}
      />
      <Redirect to="/Overview" />
      <Redirect from="/" to="/Overview" />
    </Switch>
  </Switch>
</BrowserRouter>
</HttpsRedirect>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
