import React from 'react';
import Careerslider from '../components/Career/Careerslider';
import Careercontent from '../components/Career/Careercontent';
import SysFooter from '../components/Footer';
function Career() {
  return (
    <div className="career-page page-top-header">
     
    
      <Careerslider/>
      
        <Careercontent/>
      
      
      
     
       
      <SysFooter/>
      
    
    </div>
    
    
  );
}

export default Career;
