import React, { useState } from 'react';
import logosys from '../images/SYSTEMATRIXLOGO.png';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
 
} from 'reactstrap';

const Mainmenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    
    <div className="main-menu">
      
    <Navbar expand="md" fixed="top" style={{backgroundColor:props.color}}>
      <NavbarBrand href="/" className="text-center"><img src={logosys} className="logo-sys" alt="logo" /></NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          <NavItem>
            <NavLink href="/views/" active={props.tabName === "overview"? true : false}>Overview</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/Product" active={props.tabName === "products"? true : false}>Products</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/Usecase" active={props.tabName === "usecases"? true : false}>Use Cases</NavLink>
          </NavItem>
         
          <NavItem>
            <NavLink href="/About" active={props.tabName === "aboutus"? true : false}>About Us</NavLink>
          </NavItem>
           <NavItem>
            <NavLink href="/Testimonial" active={props.tabName === "testimonial"? true : false}>Testimonials</NavLink>
          </NavItem> 
          <NavItem>
            <NavLink href="/Career" active={props.tabName === "careers"? true : false}>Careers</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/Contact" active={props.tabName === "contactus"? true : false}>Contact Us</NavLink>
          </NavItem>
          
          
        </Nav>
       
      </Collapse>
    </Navbar>
    
  </div>
    
  );
}

export default Mainmenu;