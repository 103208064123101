import React from 'react';

import Overviewslider from './Overviewslider';


function OverviewHeader() {
  return (
    <div>

      <div className="main-slider">
      <Overviewslider/>
      </div>
    </div>
  );
}

export default OverviewHeader;
