import React from 'react';
import Mainmenu from '../Mainmenu';
import { Container} from 'reactstrap';
import arrow1 from '../../images/arrow-down-alt2.svg';
function Productslider() {
  return (

      
       <div className="page-header-img">
   
   <Container fluid>
         <div>
          <Mainmenu tabName="products" color="#584e3c"/>
         <div className="page-header-content">
            <div className="content-headtext">
             <h2 className="text-center">GAIA – An end to end AI platform  </h2>
             <h2 className="text-center mt-5">using linked data using Graph Analytics</h2>
             <h2 className="text-center"><img src={arrow1}  alt="logo" className="arrow-slider"/> </h2>
             </div>
             </div>
         </div>
      
    </Container>
       </div>

     
    
    
  );
}

export default Productslider;
