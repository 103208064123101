import React, { useState } from 'react';
import Mainmenu from '../Mainmenu';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

const items = [
  {
   
    src:  require("../../images/1.png"),
    altText: 'Slide 1',
    caption: 'Transform your Risk & Compliance programs ',
    caption1:'with Advanced AI/ML analytics'
  },
  {
    src: require("../../images/2.png"),
    altText: 'Slide 2',
    caption: 'GAIA – An end to end AI platform',
    caption1:'using linked data using Graph Analytics'
  },
  {
    src: require("../../images/3.png"),
    altText: 'Slide 3',
    caption: 'Simplify Your AI/ML implementation',
    caption1: 'with a clear ROI'
  }
];

const Overviewslider = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
  
   <img src={item.src} alt={item.altText} className="slider-img"/>
   <CarouselCaption captionText={item.caption1} captionHeader={item.caption} />
  </CarouselItem>
    );
  });

  return (
      <div>
          <Mainmenu tabName="overview" color="#584e3c"/>
          <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
      </div>
   
  );
}

export default Overviewslider;