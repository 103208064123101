import React, { useState } from 'react';

import {
  Container,  CardText, Button, CardTitle, 
  CardBody, Modal, ModalHeader, ModalBody,Row,Col
} from 'reactstrap';
import Carousel, { consts } from 'react-elastic-carousel';
import Item from '../Usecases/SimpleItem';
import closearrow from '../../images/closeicon.svg';
import Getstarted from '../getstarted.js';
import { Scrollbars } from 'react-custom-scrollbars';
import leftArrow from '../../images/Teams-arrow-left.svg';
import rightArrow from '../../images/Teams-arrow-right.svg';
import Slide from 'react-reveal/Slide';
function Testimonialcontent() {
  
  const [modal, setModal] = useState(undefined);

  const toggle = (name) => setModal(name);
  const closeBtn = <button className="close" onClick={() => toggle(undefined)}><img src={closearrow} alt="close" /></button>;
  
  var breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ]
  const customArrows = ({type, onClick, isEdge}) => {
    const pointer = type === consts.PREV ? leftArrow : rightArrow;
    const arrowStyle = type === consts.PREV ? 'sc-AxjAm gEIJqc rec-arrow left-arrow' : 'sc-AxjAm gEIJqc rec-arrow right-arrow';
    return (
      // <Button onClick={onClick} disabled={isEdge}>
      <div className="rect-arrows">
       <img src={pointer} className={arrowStyle} onClick={onClick} disabled={isEdge} alt="arrow"></img>
       </div>

      // </Button>
    )
  }
  return (
   
    <div className="career-content-section testimonial-content-section">
                  <Row>
                    <Col className="mt-60">
                    <h2 className="text-center head"> Meet Our Clients</h2>
                  <div className="clients">
                      <ul className="client-list">
                      <li>
                              <span><img  src={require("../../images/clientlogo/1client.png")} alt="client-logo"/></span></li>
                          <li> <span><img  src={require("../../images/clientlogo/2client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/3client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/4client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/5client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/6client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/7client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/8client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/9client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/10client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/11client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/12client.png")} alt="client-logo"/></span></li>


                      </ul>
                  </div>
                    </Col>
                </Row>
      <Container fluid className="no-padding testimonial-container">
     
    

        <div className="rectangle-box">
        <Slide left duration={2000}>
        <Carousel className="systemarix-textcarsolue" breakPoints={breakPoints} renderArrow={customArrows}>
       
        <Item className="card testimonials-text  mtop modals sm-text-center">
        <CardBody>
                <CardText>Systematrix is a unique firm that understands technology, data science and financial services business processes. ...</CardText>
                <div className="text-center">
                  <Button color="link" onClick={() => toggle("John")}>Read More</Button>
                </div>
                <hr/>
                <CardTitle>John Krenitsky</CardTitle>
                <ul className="testimonial-user">
                  <li>Chief Compliance Officer</li>
                  <li>Discover Financial Services (2015-2018) </li>
                  <li>BancWest Corporation and Bank of the West (2010 - 2015) </li>
                  <li>US subsidiaries of BNP Paribas</li>
                </ul>
              </CardBody>
                
              <Modal isOpen={modal==="John"} toggle={() => toggle(undefined)} size="lg" className="testimonial-modal">
                <ModalHeader toggle={() => toggle(undefined)} close={closeBtn}> <img  src={require("../../images/comment_collan.svg")} alt="testimonial" className="mobile-img"/></ModalHeader>
                <ModalBody>
                  <Scrollbars style={{ height: 400 }}>
                    <p>
                      Systematrix is a unique firm that understands technology, data science and financial services business processes. While I was Chief Compliance Officer of BancWest Corporation and Bank of the West, we deployed a solution (Wire Master Data Management or "Wire MDM") that takes the raw data from wire transactions, interprets it, enriches it and structures it so that our AML wire transaction monitoring module worked. Almost ten years later, the solution remains in place and numerous other large institutions using it.
                      When I later became the CCO at Discover Financial Services, we had an even bigger challenge.
                    </p>
                    <p>Traditional AML monitoring solutions designed for branch-based banks were not effective. Systematrix deployed at Discover an entire platform that leverages algorithmic methods, artificial intelligence and machine learning (which it calls "GAIA").</p>
                    <p>Discover successfully used the GAIA platform to: </p>
                    <ol className="list-1">
                      <li> Perform AML/KYC customer risk scoring on its entire customer population. </li>
                      <li> Perform effective AML transaction monitoring. </li>
                      <li>  Gain a unified view of all of its network merchants. And</li>
                      <li>  Dramatically improve the effectiveness, quality and speed of its fraud and AML investigations with graph network visualization</li>

                    </ol>
                    <p>GAIA is even capable of helping Discover dramatically reduce its fraud losses. The capabilities of Wire MDM and GAIA are powerful and work better than any other commercially available solution
                    I have experienced over the fifteen years I have been a Chief Compliance Officer in large,
              complex financial institutions.</p>
                    <CardTitle>John Krenitsky</CardTitle>
                    <ul className="testimonial-user">
                      <li>Chief Compliance Officer</li>
                      <li>Discover Financial Services (2015-2018) </li>
                      <li>BancWest Corporation and Bank of the West (2010 - 2015) </li>
                      <li>US subsidiaries of BNP Paribas</li>
                    </ul>
                  </Scrollbars>
                </ModalBody>
              </Modal>
        </Item>

        <Item className="card testimonials-text mtop">
        <CardBody>
                <CardText> Systematrix is a boutique organization that solved one of the most complex problems in the Payments Network Domain... </CardText>
                <div className="text-center">
                  <Button color="link" onClick={() => toggle("Anand")}>Read More</Button>
                </div>
                <hr/>
                <CardTitle>Anand Narasimhan</CardTitle>
                <ul className="testimonial-user">
                <li>Head of AML Technology, Discover Financial Services <br/>(2014-2019) </li>
                <li>Head Of Analytics, Payments Network, Discover Financial Services (2004-2014) </li>

                </ul>
              </CardBody>
              <Modal isOpen={modal==="Anand"} toggle={() => toggle(undefined)} size="lg" className="testimonial-modal">
              <ModalHeader toggle={() => toggle(undefined)} close={closeBtn}> <img src={require("../../images/comment_collan.svg")} alt="testimonial" className="mobile-img"/></ModalHeader>
              <ModalBody>
              <Scrollbars style={{ height: 400 }}>
                <p>

                  Systematrix is a boutique organization that solved one of the most complex problems in the Payments Network Domain. While I was the head of Analytics for the Payments Network, we tried working with many Vendors to solve for
                  identifying the merchant behind the Point of Sale(POS) transaction.
                </p>
                <p>
                  POS data is behest with Noise, as it has value and position ambiguity, incomplete names, acronyms, wrong addresses and many more variations that makes it difficult to decipher the string of Text that comes in the POS. This bad data ends up in the Cardmember statement which results in bad customer experiences, losses due to disputes and many more operational Challenges
                </p>
                <p>
                  Systematrix took a different approach by applying some of the Natural Language Processing techniques coupled with Google Page rank validation and mimicked how a human will search for a string in the world wide web and systematically cleansed and identified the merchant behind the POS and validated that the merchant Exist in a given location too. By doing so,  the accuracy of the data moved from 60% to 93% and improving.
                  This was a huge differentiator and a big value proposition for the Network.
                </p>
                <CardTitle>Anand Narasimhan</CardTitle>
                <ul className="testimonial-user">
                  <li>Head of AML Technology, Discover Financial Services (2014-2019) </li>
                  <li>Head Of Analytics, Payments Network, Discover Financial Services (2004-2014) </li>
                </ul>
                </Scrollbars>
              </ModalBody>
            </Modal>
          </Item>
          <Item className="card testimonials-text mtop">
        <CardBody>
                <CardText> Balaji and I collaborated on the Customer MDM project at Northern Trust around 2012.  He innovated on techniques... </CardText>
                <div className="text-center">
                  <Button color="link" onClick={() => toggle("Alex")}>Read More</Button>
                </div>
                <hr/>
                <CardTitle>Alex Aquino </CardTitle>
                <ul className="testimonial-user">
                <li>Northern Trust, Solution Architect - Legal Entity <br/>Master Data Management (2012) </li>
                

                </ul>
              </CardBody>
              <Modal isOpen={modal==="Alex"} toggle={() => toggle(undefined)} size="lg" className="testimonial-modal">
              <ModalHeader toggle={() => toggle(undefined)} close={closeBtn}> <img src={require("../../images/comment_collan.svg")} alt="testimonial" className="mobile-img"/></ModalHeader>
              <ModalBody>
              <Scrollbars style={{ height: 300 }}>
                <div className="small-content">
                <p>

                Balaji and I collaborated on the Customer MDM project at Northern Trust around 2012.  He innovated on techniques to augment the detection and matching of potentially related customer records, using early machine learning algorithms improved by feedback from data stewards.  His drive and innovation were early clues to his demonstrated focus on improving critical master data
                </p>
               
                <CardTitle>Alex Aquino </CardTitle>
                <ul className="testimonial-user">
                  <li>Northern Trust, Solution Architect - Legal Entity Master Data Management (2012) </li>
              
                </ul>
                </div>

                </Scrollbars>
              </ModalBody>
            </Modal>
          </Item>
         

          </Carousel>

          {/* <CardDeck>
            <Card className="testimonials-text mtop modals sm-text-center">
            
            </Card>
            <Card className="testimonials-text mtop">
           
            </Card>
        
          </CardDeck> */}
        
        </Slide>
        </div>
     
      </Container>
      <Container fluid className="bg-gray">
        <Getstarted />
      </Container>
    </div>

  );
}

export default Testimonialcontent;