import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Input } from 'reactstrap';
import Iframe from 'react-iframe'
import { withRouter } from "react-router-dom";

// var history = createBrowserHistory()
function Contactcontent(props) {
    const [success, setSuccess] = useState(false);
    const [firstName, setFirstName] = useState(undefined);
    const [lastName, setLastName] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [message, setMessage] = useState(null);
    
    var sendMessage = (e) => {
       e.preventDefault();
        console.log(firstName, lastName, email, message)
        var userData = {firstName, lastName, email, message}
        // console.log(userData);
        fetch('/userData', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            method:"POST",
            body:JSON.stringify(userData)
        }).then (res => {          
            res.json().then(res =>{ 
                console.log(res);
                if(res.error){
                    alert("Internal server error, please try later.")
                }
		else {
                    setSuccess(true);
                    setTimeout(() => {
                        props.history.push("/view");
                    },2000); 
                }
            });
        });       
    }
    
    return (
        <div className="career-content-section">
            <Container fluid>
                <Row>
                    <Col className="mt-60">
                        <h2 className="text-center head"> Reach Us</h2>
                        <p className="head-black text-center m-20 pno">
                            Systematrix envisions simplifying business processes with advanced Algorithmic models.
                                Our team is motivated to delve deep into Data Science to create cutting edge products.</p>
                    </Col>
                </Row>
                <Row className="mtop-5">
                    <Col lg={{ size: 9, offset: 2 }} md="12">
                        <Row>
                            <Col md="6">
                                <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.456712778705!2d-84.35002978558587!3d33.98080098062545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50b78c3e1ebcb%3A0x3169b0264e90fc83!2s365%20Northridge%20Rd%20%23200%2C%20Atlanta%2C%20GA%2030350%2C%20USA!5e0!3m2!1sen!2sin!4v1596464599336!5m2!1sen!2sin"
                                id="contact-map"
                                className="map-style"
                                display="initial"
                                position="relative" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" ></Iframe>
                            </Col>
                            
                            <Col md="6">
                            <div className="address">
                                    <h3>Systematrix Solutions</h3>
                                    <p>#365 Northridge Road Suite,</p>
                                    <p>200 Sandy Springs GA 30350</p>
                                    <p className="mt-5"> Phone:(+1) 614-678-4343</p>
                                    <p>Email: contact@systematrix.ai</p>
                                    
                                </div>
                                <div className="contact-form">
                                    <h3 className="text-orange ">Talk to our Experts</h3>
                                    <Form onSubmit={(e) => sendMessage(e)}>
                                        <div className={success ? "hide" : ""}>
                                        <FormGroup>
                                            <Input type="text" name="firstName" placeholder="First Name" className="text-field" 
                                                value={firstName} onChange={(e) => setFirstName(e.target.value)} required/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input type="text" name="lastName" placeholder="Last Name" className="text-field" 
                                                value={lastName} onChange={(e) => setLastName(e.target.value)} required/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input type="email" name="email" placeholder="Email" className="text-field "
                                                onChange={(e) => setEmail(e.target.value)} required />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input type="textarea" name="message" className="text-field custom-text-area" placeholder="Enter Message (Optional)" 
                                                onChange={(e) => setMessage(e.target.value)}/>
                                        </FormGroup>
                                        <div className="text-center">
                                            <Button type="submit" color="primary" size="lg" 
                                                className="rounded btn-pad">
                                                    Send Message
                                            </Button>
                                        </div>
                                        </div>
                                        
                                        <div className={success ? "" : "hide"}>
                                             <div className="text-center">
                                            <i className="fa fa-check-circle  fa-5x check-icon" aria-hidden="true"></i>
                                            <h2 className="success-msg">Your message has been submitted Successfully</h2>
                                            </div>

                                        </div>

                                     
                                    </Form>
                                </div>
                           </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default withRouter(Contactcontent);
