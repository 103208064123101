import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink,  Row, Col } from 'reactstrap';

import classnames from 'classnames';

const Usecasetabs = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div className="mt-60">

      
        <Row>

          
          <Col sm="12" md="12">

         
       
            <Row className="ml-30">
            <Col md="3" className="flex-40">
            <Nav tabs vertical pills>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}>
            Solving AML below the
line alerting engine 

          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
           Detect your Potential
Charge-off or Delinquencies
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
           Combat Fraud with
our platform
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => { toggle('4'); }}
          >
           Customer Master Data
Management using Graph 
          </NavLink>
        </NavItem>
      </Nav>
            </Col>
            <Col md="8" className="flex-60">
            <TabContent activeTab={activeTab} className="usecase-tabcontent">
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <h3 className="light-green text-center">
          <span className="orange">Solving AML</span>   below the line alerting engine and advanced visualization to all the associated artifacts.
            </h3>
            <Row>
              <Col>
              <div className="m-20 fit-img">
                <img alt="Solutionpage " src={require("../../images/solutionpage-macbook.png")}/>
                </div>
              </Col>
            </Row>
           

                <ul class="list-checkmark">
                  <li>Reduce your false positives by 40% </li>
                <li> Alerting engine that recommends from the benign population with a high alert to SAR ratio </li>
                <li> Reduce all your investigation, however complex to less than 30 minutes</li>
                </ul>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col md="12">
            <h3 className="orange text-center">
         Detect your<span> Potential Charge-off or </span> Delinquencies
            </h3>
            <Row>
              <Col>
              <div className="m-20 fit-img">
                <img alt="Solutionpage " src={require("../../images/solutionpage-2.png")}/>
                </div>
              </Col>
            </Row>
       

                <ul className="list-checkmark">
                            <li>50% of your charged-off accounts have a connected entity within the enterprise who have charged-off previously </li>
                          <li> Reduce your search space for detecting future charge-off  </li>
                         <li>   Improve your precision and recall.</li>
                      </ul>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
            <h3 className="orange text-center">
            Combat Fraud <span>with our platform</span>
        
            </h3>
            <div className="m-20 fit-img">
                <img alt="Solutionpage " src={require("../../images/solutionpage-3.png")}/>
                </div>

                <ul className="list-checkmark">
                            <li> Increased provisioning of electronic payment methods have given rise to vulnerabilities due to Identity theft.  </li>
                          <li>  Creating feature vectors using the baseline toxic proximity communities   </li>
                         <li>   Reduce fraud by 30%</li>
                      </ul>
         
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col sm="12">
            <h3 className="orange text-center">
            Customer Master Data Management <span>using Graph</span>
           
        
            </h3>
            <div className="m-20 fit-img">
                <img alt="Solutionpage " src={require("../../images/solutionpage4.png")}/>
                </div>

                <ul className="list-checkmark">
                            <li> Your MDM is a master Graph   </li>
                          <li>  Singular customer entity across all products   </li>
                         <li>    Propreitary ML algorithms reduced your suspect list by 90%.</li>
                      </ul>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
            </Col>
      
            </Row>
          </Col>
           
        </Row>
   
     
    </div>
  );
}

export default Usecasetabs;