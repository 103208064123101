import React from 'react';
import { Container, Row, Col, Button, NavLink } from 'reactstrap';
import overviewanim from '../../images/giffy/overviewanim.gif';
import productsuite from '../../images/productsuiteoverview.gif';
import Slide from 'react-reveal/Slide';

import Pulse from 'react-reveal/Pulse';

function Overviewcontent() {
    return (
        <div className="overview-content-section">
            <Container fluid>
                <Row className="over-view">
                    <Col md="12">

                        <h4 className="head-black text-center m-20 p-20">  In these times of great uncertainty and potential market volatility, visualize and unleash the full
                        potential of an interconnected enterprise. Machine learning is the key to futuristic solutions and
                        insights.
                        </h4>
                    </Col>
                    <Col className="bg-light" md="12">
                    <Slide left duration={3000}>
                        <h4 className="head-blue text-center m-20">
                            GAIA will help realize this potential by accelerating your
                            machine learning programs with potent and highly predictive
                            features.
                        </h4>
                        </Slide>
                    </Col>
                    <Col md="12">
                        <h4 className="head-black text-center m-20 mb-20" >
                            Cut the time taken to build best in class ML models to literally
                            weeks instead of years.
                            Operationalize predictive models with a few short and repeatable steps.
                        </h4>
                    </Col>

                </Row>
                <Row className="flagship-section" >
                    <Col>
                    <div className="p-80 product-info">
                        <h2 className="text-center head"> Our Flagship Product -GAIA</h2>
                        <p className="head-black text-center m-20"> Convey the concepts of data management and data democratization, how we enable and enforce
                        enterprise level collaboration by giving a singular view and interpretation of data across all and
                           any stakeholder.</p>
                        <div className="m-20  fit-img">
                        <Pulse>
                            <img src={overviewanim} className="text-center border-purple img-fluid" alt="flagship product" />
                            </Pulse>
                        </div>
                        </div>
                    </Col>
                </Row>
                
                <Row className="productsuite-section" >
                    <Col md="12">
                        <h2 className="text-center head"> Our Product suite</h2>
                      
                        <p className="head-black text-center m-20"> Powerful end to end platform for financial crimes that helps you reduce your operational burden
                           and increases your precision and recall.</p>
                           </Col>
                      <Col md="12" className="light-gray">
                  
                        <div className="m-20 fit-img">
                        <NavLink href="/Product"> <img src={productsuite} className="text-center img-fluid" alt="Product Suite" /></NavLink>

                          
                        </div>
                        
                    </Col>
                </Row>
                <Row className="our-client">
                    <Col>
                    <h2 className="text-center head"> Our Clients</h2>
                  <div className="clients">
                      <ul className="client-list">
                          <li>
                              <span><img  src={require("../../images/clientlogo/1client.png")} alt="client-logo"/></span></li>
                          <li> <span><img  src={require("../../images/clientlogo/2client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/3client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/4client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/5client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/6client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/7client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/8client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/9client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/10client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/11client.png")} alt="client-logo"/></span></li>
                          <li><span><img  src={require("../../images/clientlogo/12client.png")} alt="client-logo"/></span></li>

                      </ul>
                  </div>
                    </Col>
                </Row>
                <Row className="getstarted-section mt-60">

                <Col md="12">
                      
                        <h2 className="1 text-center p20"> Talk to our Experts for better solution
                        </h2>
                        <div className="m-20 text-center">
                        <NavLink href="/Contact">  <Button color="primary" size="lg" className="rounded btn-pad">Get Started</Button></NavLink>
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    );
}

export default Overviewcontent;