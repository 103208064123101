import React from 'react';

import Mainmenu from '../Mainmenu';
import { Container} from 'reactstrap';
import arrow1 from '../../images/arrow-down-alt2.svg';
function Usecaseslider() {
  return (

    
       <div className="page-header-img">
   
   <Container fluid>
         <div>
          <Mainmenu tabName="usecases" color="#4990E6"/>
         <div className="page-header-content">
             <div className="content-headtext">
             <h2 className="text-center">Use Cases</h2>
             <h3 className="text-center"><img src={arrow1}  alt="logo" className="arrow-slider"/> </h3>
            </div>
             </div>
         </div>
      
    </Container>
       </div>

     
    
    
  );
}

export default Usecaseslider;
