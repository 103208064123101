import React from 'react';
import Usecaseslider from '../components/Usecases/Usecaseslider';
import Usecasecontent from '../components/Usecases/Usecasecontent';

import SysFooter from '../components/Footer';
import Getstarted from '../components/getstarted';
function Usecase() {
  return (
    <div className="usecase-page page-top-header">
        <Usecaseslider/>
         <Usecasecontent/>
         <Getstarted/>
     
       
      <SysFooter/>
      
  
    </div>
    
    
  );
}

export default Usecase;
