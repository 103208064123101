import React from 'react';
import {Row,Col,Button,NavLink } from 'reactstrap';

function Getstarted() {
  return (
    <Row className="getstarted-section">

    <Col>
          
            <h2 className="1 text-center p20"> Talk to our Experts for better solution
            </h2>
            <div className="m-20 text-center">
            <NavLink href="/Contact">  <Button color="primary" size="lg" className="rounded btn-pad">Get Started</Button></NavLink>
            </div>
        </Col>
    </Row>
    
  );
}

export default Getstarted;
