import React from 'react';
import Mainmenu from '../Mainmenu';
import { Container} from 'reactstrap';
import arrow1 from '../../images/arrow-down-alt2.svg';
function aboutslider() {
  return (

    
       <div className="page-header-img">
   
   <Container fluid>
      
         <div>
          <Mainmenu tabName="aboutus" color="#305c5c"/>
          <div className="page-header-content">
               <div className="content-headtext">
             <h2 className="text-center">Experts in leveraging graph networks </h2>
             <h2 className="text-center mt-5">for predictive analysis</h2>
             <h3 className="text-center"><img src={arrow1}  alt="logo" className="arrow-slider"/> </h3>
             </div>
             </div>
         </div>
      
    </Container>
       </div>

     
    
    
  );
}

export default aboutslider;
