import React from 'react';
import { Container,Row,Col,NavLink} from 'reactstrap';
import Careerslider from '../Career/Careerslider';
import SysFooter from '../Footer';
import backarrow from '../../images/back-arrow.svg';
function Jobdetails() {
  return (
    <div className="career-page">
   <Container fluid>
      
       <Careerslider/>
       
         <Row>
             <Col  md={{ size: 10, offset: 1 }}> <NavLink href="/Career" className="back-btn"><img src={backarrow}  alt="backarrow" />  <span className="back-btn-text">Back to see more jobs</span></NavLink></Col>
         </Row>
         <Row className="job-details">
         <Col  md={{ size: 10, offset: 1 }}>
            
             <h2 className="job-title-head">
                Python Developer
             </h2>
             <div className="job-des">
             <h3>  Job description</h3>
             <h5>Responsibilities and Duties:</h5>
             <ul className="jobdes-list">
                 <li>Translate UI/UX designs or wireframes to the actual code to produce</li>
                 <li>visual elements of the application using Angular/React.</li>

                 <li>Bridge the gap between graphical design and technical implementation.</li>

                 <li>Build reusable code for future use.</li>

                 <li>Ensure the technical feasibility of UI/UX designs.</li>

                 <li>Ensure continual knowledge enhancement.</li>

                 <li>Participat e in planning activities and collaborative work with the team.</li>

                 <li>Optimize application for maximum speed and scalability.</li>
             </ul>
             <h3>  Job requirements</h3>
             <ul className="jobdes-list">
                 <li>Bachelor Degree or higher in Computer Science, Engineering or a related 
                    subject.
                  </li>
                    <li>
                    3+ year front-end development experience using the following 
                    technologies- Angular 4+,React, HTML5, Bootstrap,JavaScript, Git, Ionic.
                    </li>
                    <li>Any server-side technology (preferably node.js).</li>
                    <li>Knowledge about git and its workflows.Gulp / Grunt.</li>
            </ul>
            </div>
        </Col>
         </Row>
          <Row>
              <Col md={{ size: 8, offset: 2 }}> 
              <h2 className="text-center btm-text info"> If you think you fit in with the above requirements we d love to talk to you
                       about working in our organization.</h2>
              </Col>
          </Row>
           
   
   <Row className="getstarted-section">

<Col md={{ size: 8, offset: 2 }}>
      
        <h2 className="text-center p20 btm-text"> To apply for the job please mention the job title &</h2>
        <h2 className="text-center btm-text">send us your resume <span className="mail-info">Careers@systematrix.ai </span>
       
        </h2>
        <div className="m-20 text-center">
      
        </div>
    </Col>
</Row>
  







 <Row>
 <SysFooter/>
 </Row>
   </Container>
      

     </div>
    
    
  );
}

export default Jobdetails;
